//////////////////////
////////////////////// Common Slider
//////////////////////
(function (window, document, $) {
	"use strict";

	var CommonSliderTestimonio = function (element) {
		this.container = element;
		this.$container = $(element);
		this.$slides = this.$container.find('[data-role="slider-slide"]');
		this.$arrows = this.$container.find('[data-role="slider-arrow"]');
		this.$bullets = this.$container.find('[data-role="slider-bullet"]');
		this.$slidelist = this.$container.find('[data-role="slider-list"]');


		this.$slidelist.find('.slider__slide:first-child').addClass('current').attr('data-current', true);
		this.$arrows[0].style.visibility = 'hidden'

		this.$arrows.on('click.CommonSliderTestimonio', this.arrowCallback.bind(this));
		this.$bullets.on('click.CommonSliderTestimonio', this.bulletCallback.bind(this));
	};

	CommonSliderTestimonio.prototype = {
		transitionCallback: function (index) {
			if (this.$bullets.length) {
				this.$bullets.removeClass('slider__bullet--current');
				this.$bullets.filter('[data-target="' + index + '"]').addClass('slider__bullet--current');
			}

			if (this.$slides.length) {
				this.$slides.removeClass('current').attr('data-current', false);
				this.$slides.filter('[data-index="' + index + '"]').addClass('current').attr('data-current', true);
			}

			if (index == +this.$slides[0].dataset.index) {
				this.$arrows[0].style.visibility = "hidden";
				this.$arrows[1].style.visibility = "visible";
			} else if (index == this.$slides.length - 1) {
				this.$arrows[0].style.visibility = "visible";
				this.$arrows[1].style.visibility = "hidden";
			} else {
				this.$arrows[0].style.visibility = "visible";
				this.$arrows[1].style.visibility = "visible";
			}
		},

		arrowCallback: function (event) {
			event.preventDefault();
			let $btn = $(event.currentTarget);
			let direction = $btn.data('direction');
			let currentSlide = this.$slidelist.find('[data-current="true"]');
			let currentIndex = $(currentSlide).data('index');
			let index;

			if (direction == 'next') {
				index = currentIndex + 1;
			} else if (direction == 'prev') {
				index = currentIndex - 1;
			} else {
				throw new Error('El valor de [data-direction] no es correcto.')
			}

			let slideTarget = this.$slidelist.find(`[data-index="${index}"]`);

			if (slideTarget.length > 0) {
				this.transitionCallback(index);
				slideTarget[0].scrollIntoView({ block: "nearest", behavior: "smooth", inline: "start" });
			}
		},

		bulletCallback: function (event) {
			event.preventDefault();
			let $btn = $(event.currentTarget);
			let target = $btn.data('target');

			let slideTarget = this.$slidelist.find(`[data-index="${target}"]`);

			if (slideTarget.length > 0) {
				this.transitionCallback(target);
				slideTarget[0].scrollIntoView({ block: "nearest", behavior: "smooth", inline: "start" });
			}
		}
	};

	$.fn.commonSliderTestimonio = function () {
		if (this.data('commonSliderTestimonio')) { return this.data('commonSliderTestimonio'); }
		return this.each(function (i, el) {
			$(el).data('commonSliderTestimonio', (new CommonSliderTestimonio(el)));
		});
	};

	// Self initialization
	jQuery(function () {
		$('[data-module="slider-testimonio"]').commonSliderTestimonio();
	});
}(window, document, jQuery));